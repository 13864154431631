@font-face {
  font-family: "Caspar";
  src: url(./fonts/Caspar-Condensed-Bold-v.0.1.otf) format("opentype");
}

@font-face {
  font-family: "RT Dromo Book";
  src: url(./fonts/RTDromo-Book.woff) format("woff");
}

body {
  margin: 0;
  background: black;
  color: #e7e7f2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
